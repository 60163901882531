import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as actions from "../../_redux/landingPageActions";
import Description from "../description/index.js";
import LandBookingItems from "../landBookingItems/index.js";
import LandInfo from "../landinfo/index.js";
import Locations from "../locations/index.js";
import MapLocation from "../map/index.js";
import Reviews from "../reviews/index.js";
import Schedule from "../schedule/index.js";
import ThumbnailCarusal from "../thumbnailCarusal/index.js";
import TopBar from "../topBar.js/index.js";
import Trophies from "../trophies/index.js";
import VoucherCard from "../TabVoucherCard/index.js";
import CustomSpinner from "components/layout/Loader/Spinner";
import StandBlog from "../StandBlog";
import Followers from "../followers";
import config from "config";
import "./style.scss";

export default function StandDetail({ id }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const {
    standDetails,
    weatherReport,
    relatedLands,
    user,
    actionsLoading
  } = useSelector(
    (state) => ({
      standDetails: state.landingPage.standDetails,
      weatherReport: state.landingPage.weatherReport,
      relatedLands: state.landingPage.relatedLands,
      actionsLoading: state.landingPage.actionsLoading,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const [key, setKey] = useState("");
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (key === "reviews" || key === "followers" || key === "blog" || key === "trophies") {
      window.scroll({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [key, id]);

  useEffect(() => {
    if (!id) return;
    dispatch(actions.fetchStandDetail(id));
    return async () => {
      await dispatch(actions.clearStandDetailsFectched());
    };
  }, [dispatch, id]);

  const getKey = () => {
    if (query.get("from") === "stand-to-review") return "reviews"
    if (query.get("from") === "notification-followers") return "followers";
    if (query.get("from") === "notification-blog") return "blog";
    if (user?.id == standDetails?.user?.id) {
      return "schedule";
    } else return "description";
  };

  useEffect(() => {
    setKey(getKey());
    if (
      !standDetails ||
      !standDetails?.property_address_longitude ||
      !standDetails?.property_address_latitude
    )
      return;
    let data = {
      appid: config.WEAHTER_APP_ID,
      units: "imperial",
      lat: standDetails?.property_address_latitude,
      lon: standDetails?.property_address_longitude,
    };
    dispatch(actions.fetchWeatherReport(data));
  }, [standDetails]);


  useEffect(() => {
    if (location?.state?.fromReservation === true) setKey("location");
  }, [location]);

  return (
    <>
      {standDetails?.status === "rejected" ? (
        <TopBar land={standDetails} />
      ) : (
        <TopBar />
      )}

      <div className="inner-container">
        {actionsLoading ? (
          <CustomSpinner />
        ) : (
          <div className="row">
            <div className="col-lg-8 pe-lg-5">
              <ThumbnailCarusal pictures={standDetails?.pictures} />
            </div>

            <div className="col-lg-4">
              <LandInfo land={standDetails} setKey={setKey} />
            </div>
          </div>
        )}

        <div className="row font-roboto mt-5 pt-4 mb-5">
          {standDetails ? <div className="">
            <div className="tab">
              <Tabs
                transition
                defaultActiveKey={getKey()}
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                }}
              >
                {standDetails?.user?.id === (user && user?.id) ? (
                  <Tab eventKey="schedule" title="Schedule">
                    <Schedule standId={id} value={value} setValue={setValue} />
                  </Tab>
                ) : null}
                <Tab eventKey="description" title="Description">
                  <Description
                    key={standDetails?.id}
                    land={standDetails}
                    weatherReport={weatherReport}
                  />
                </Tab>
                {location?.state?.fromReservation === true ? (
                  <Tab eventKey="location" title="Go Hunt!">
                    <Locations land={standDetails} />
                  </Tab>
                ) : null}
                <Tab eventKey="trophies" title="Trophies">
                  <Trophies standId={id} />
                </Tab>
                <Tab eventKey="reviews" title="Reviews">
                  <Reviews standId={id} />
                </Tab>
                {(user != null && standDetails?.user?.id) === (user && user?.id) ? (
                  <Tab eventKey="Vouchers" title="Vouchers">
                    <VoucherCard 
                      vouchers={standDetails?.stand_vouchers}
                      standId={standDetails?.id}
                      land={standDetails}
                    />
                  </Tab>
                ) : null}
                <Tab eventKey="blog" title="Blog">
                  <StandBlog
                    key={standDetails?.id}
                    standId={standDetails?.id}
                    land={standDetails}
                  />
                </Tab>
                {user != null && standDetails?.user?.id === user?.id && (
                  <Tab eventKey="followers" title="Followers">
                    <Followers standId={id} key={standDetails?.id} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div> : null
          }
          {location?.state?.fromReservation === true && key === "location" ? (
            <div className="col-lg-4 pt-lg-5 location">
              <MapLocation standDetails={standDetails} />
            </div>
          ) : null}
        </div>

        {relatedLands.length > 0 && key === "location" && (
          <div className="related-land">
            <h4 className="mt-5 mb-3 fs-28">Related Stands</h4>
            <LandBookingItems lands={relatedLands} />
          </div>
        )}
      </div>
    </>
  );
}
