import TimeIcon from "assets/images/timer.svg";
import { getTicketDetails } from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Spinner from "components/UI/Spinner.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AddComment } from "./addComment/addComment";
import NoRecordFound from "utils/noRecordFound";
import User from "assets/images/default-avatar.png";
import "./style.scss";


export default function TicketDetailPage({ id }) {
  const [showFullContent, setShowFullContent] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getTicketDetailsAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { ticketDetail, isLoading } = useSelector(
    (state) => ({
      ticketDetail: state.customerServicePortal.ticketDetail,
      isLoading: state.customerServicePortal.listLoading,
    }),
    shallowEqual
  );

  const handleBack = () => {
    history.goBack();
  };

  const getTicketDetailsAPI = () => {
    if (!id) return;
    dispatch(getTicketDetails(id));
  }

  const toggleContent = (id) => {
    const isIdPresent = showFullContent.includes(id);
    const updatedState = isIdPresent
      ? showFullContent.filter(item => item !== id)
      : [...showFullContent, id];
    setShowFullContent(updatedState);
  };

  const formatText = (text) => {
    const urlPattern = /((https?|ftp):\/\/[^\s<]+)/g;
    const formattedText = text
    .replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`)
    .replace(/\r\n/g, "<br/>")
    .replace(/\n/g, "<br/>");
    return { __html: formattedText };
  };

  return ticketDetail !== null ? (
    <div className="ticket-detail-page">
      <div className="top-bar d-flex justify-content-between align-items-center flex-wrap">
        <div className="top" onClick={handleBack}>
          <i className="bi bi-arrow-left-short me-2"></i>
          <span>Go back</span>
        </div>
      </div>
      <div className="ticket-title">{ticketDetail.subject}</div>

      <div className="ticket-status">
        <div className="d-flex flex-row align-items-center flex-wrap">
          <div className="d-flex flex-row flex-wrap">
            <div className="date">
              <img src={TimeIcon} className="time-icon" alt="" />
              {ticketDetail.created_at}
            </div>
            <div className="separator">|</div>
            <div className="category">
              <span>
                <i
                  className="fa fa-layer-group mr-1"
                  style={{ width: "14px" }}
                />
              </span>
              {ticketDetail?.category?.title || "N/A"}
            </div>
          </div>
        </div>

        <div className={ticketDetail.status === "hold" ? "ticket-status-pending" : ticketDetail.status === "open" ? "ticket-status-open" : "ticket-status-resolved"}>{ticketDetail.status}</div>
      </div>
      <div className="ticket-detail">
        <div className="ticket-body">
          {ticketDetail?.description?.length > 500 ? showFullContent.includes(ticketDetail?.id) ? (
            <>
            <span dangerouslySetInnerHTML={formatText(ticketDetail?.description)} />
              <span onClick={() => toggleContent(ticketDetail?.id)} className="read-more">
                Show Less
              </span>
            </>
          ) : (
            <>
            <span dangerouslySetInnerHTML={formatText(ticketDetail?.description?.slice(0, 500))} />
              <span onClick={() => toggleContent(ticketDetail?.id)} className="read-more">
                Show More
              </span>
            </>
          ) : 
          <span dangerouslySetInnerHTML={formatText(ticketDetail?.description)} />}
        </div>
        {
          ticketDetail?.media ?
            <a href={ticketDetail?.media} className="attachment-btn" target="_blank">
              <i className="fas fa-paperclip"></i>
              Attachment
            </a> : null
        }
      </div>
      <AddComment id={id} getTicketDetails={getTicketDetailsAPI} />
      <div className="comments-section">
        <div className="comment-section-header">
          <div>
            Comments{" "}
            <span className="comment-count">
              ({ticketDetail.comment_count})
            </span>
          </div>
        </div>

        <ul className="comments-list">
          {ticketDetail.comments.map((comment, index) => (
            <li key={index}>
              <div className="comment">
                <div className="comment-profile-pic">
                  <img src={comment?.user?.image || User} className="profile-icon" alt="" />
                </div>
                <div className="comment-detail">
                  <div className="comment-info">
                    <h5 className="comment-user-name">{comment?.user?.first_name + " " + comment?.user?.last_name}</h5>
                    <div className="comment-date">
                      <img src={TimeIcon} className="time-icon" alt="" />
                      <span className="date">{comment.created_at}</span>
                    </div>
                  </div>
                  <div className={`comment-body${comment?.user?.id !== ticketDetail?.user?.id ? ' orange' : ''}`}>
                    <p>
                    {comment?.comment?.length > 500 ? showFullContent.includes(comment?.id) ? (
                      <>
                      <span dangerouslySetInnerHTML={formatText(comment?.comment)} />
                        <span onClick={() => toggleContent(comment?.id)} className="read-more">
                          Show Less
                        </span>
                      </>
                    ) : (
                      <>
                      <span dangerouslySetInnerHTML={formatText(comment?.comment?.slice(0, 500))} />
                        <span onClick={() => toggleContent(comment?.id)} className="read-more">
                          Show More
                        </span>
                      </>
                    ) : 
                    <span dangerouslySetInnerHTML={formatText(comment?.comment)} />}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : isLoading ? (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  ) : (
    <NoRecordFound message="No detail found" />
  );
}
