import React, { useEffect } from "react";
import "./style.scss";

export default function Term() {
  useEffect(() => {document.title = 'Terms | iHunt'}, []);
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const offset = 150;
      window.scrollTo({
        top: section.offsetTop - offset,
        behavior: "smooth"
      });
    }
  };

  return (
    <div className="mt-5 mb-5">
      <div className="inner-container">
        <h2 className="text-center">Terms of Service And Disclaimer </h2>
        <div className="faq-container mt-5 p-4">
          <h4>
            Section 22 of these Terms contains an arbitration agreement and
            class action waiver that apply to all claims brought against iHunt
            in the United States. Please read them carefully.
          </h4>
          <p>Last Updated: January 24, 2022</p>
          <p>
            Thank you for using <b>iHunt!</b>
          </p>
          <p>
            These Terms of Service (“<b>Terms</b>”) are a binding legal
            agreement between you and <b>iHunt</b> that govern your use of the
            websites, applications, and other offerings from <b>iHunt</b>. When
            used in these Terms, “<b>iHunt</b>,” “<b>we</b>,” “<b>us</b>” or “
            <b>our</b>” refers to the <b>iHunt</b> entity.
          </p>
          <p>
            The <b>iHunt</b> Platform offers an online venue that enables users
            (“<b>Members</b>”) to publish, offer, search for, and book services.
            Members who publish and offer services are “<b>Land Owners</b>” and
            Members who search for, book, or use services are “<b>Hunters</b>.”
            You must register an account to access and use many features of the{" "}
            <b>iHunt</b> Platform, and must keep your account information
            accurate. As the provider of the <b>iHunt</b> Platform,{" "}
            <b>iHunt</b> does not own, control, offer or manage Hunts.{" "}
            <b>iHunt</b> is not a party to the contracts concluded directly
            between Land Owners and Hunters, nor is <b>iHunt</b> a Land Owner
            Or Land Renter.{" "}
            <b>iHunt</b> is not acting as an agent in any capacity for any Land
            Owner, except as specified in the{" "}
            Payments Terms of Service (“<b>Payment Terms</b>”).
          </p>
          <p>
            If you Rent Land or Book Hunts, you are responsible for
            understanding and complying with all laws, rules, regulations and
            contracts with Local and Federal Laws In Your Area.
          </p>
          <h4>Table of Contents</h4>
          <h5 className="mt-3 mb-3">Hunter Terms</h5>
          <ol>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("our-mission")}>
                Our Mission.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("searching")}>
                Searching and Booking on iHunt.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("travel-issues")}>
                Cancelations, Travel Issues, Refunds and Booking Modifications.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("responsibility")}>
                Your Responsibilities and Assumption of Risk.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("listing")}>
                Listing Land on iHunt.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("manage")}>
                Managing Your Listing.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("cancelation")}>
                Cancelations, Travel Issues, and Booking Modifications.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("taxes")}>
                Taxes.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("reviews")}>
                Reviews.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("content")}>
                Content.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("fees")}>
                Fees.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("platform-rules")}>
                {" "}
                iHunt Platform Rules.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("termination")}>
                Termination, Suspension and other Measures.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("modification")}>
                {" "}
                Modification.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("ihunt-role")}>
                iHunt’s Role.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("accounts")}>
                Member Accounts.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("warranties")}>
                Disclaimer of Warranties.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("limitations")}>
                Limitations on Liability.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("indemnification")}>
                Indemnification.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("contract")}>
                Contracting Entities.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("governing-law")}>
                United States Governing Law and Venue.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("dispute-resolution")}>
                United States Dispute Resolution and Arbitration Agreement.
              </a>
            </li>
            <li className="mt-3">
              <a className="cursor-pointer" onClick={() => scrollToSection("miscellaneous")}>
                Miscellaneous.
              </a>
            </li>
          </ol>
          <h4>Hunter Terms</h4>
          <section id="our-mission">
            <h5 className="mt-3 mb-3">1. Our Mission.</h5>
            <p>
              Our mission is to create a world where you can Hunt anywhere, from
              small farms to large hunting reserves, connecting the hunter with
              his dreams.
            </p>
          </section>
          <section id="searching">
            <h5 className="mt-3 mb-3">2. Searching and Booking on iHunt</h5>
            <p>
              <b>2.1 Searching.</b> You can search for your Hunt by using
              criteria like the type of Stand, Destination, Dates, Price and
              kind of game. You can also use filters to refine your search
              results. Search results are based on their relevance to your
              search and other criteria. Relevance considers factors like price,
              availability, Reviews to name a few.
            </p>
            <p>
              <b>2.2 Booking. </b> When you book a hunt, you are agreeing to pay
              all charges for your booking including the Listed price of hunt,
              applicable fees like iHunt’s service fee, {" "}
              taxes, and any other items identified during final
              payment of hunt (collectively, “<b>Total Price</b>”). You are also
              agreeing that iHunt Payments may charge and collect full cost of
              base hunt when you book a hunt. When you receive the booking
              confirmation, a contract is formed directly between you, the
              Hunter, and the Land Owner. The cancelation policy and any other
              rules, standards, policies, or requirements identified in the
              Listing or during checkout form part of your contract with the
              Land Owner. Be aware that some Land Owners work with a co-Land
              Owner or Hunt Brokers or as part of a team to provide their Land
              for your hunting experience.
            </p>
            <p>
              <b>2.3 Hunt Reservations.</b> A hunt reservation is a limited
              license to enter, occupy, and use the Land of said Land Owner for
              the single purpose of hunting for the specified game listed on
              hunt reservation. The Land Owner retains the right to enter the
              Land on hunting listing at any time during your hunt, to the
              extent: (i) it is reasonably necessary, (ii) permitted by your
              contract with the Land Owner, and (iii) consistent with applicable
              law. If you stay past allotted time of hunt, the Land Owner has
              the right to make you leave in a manner consistent with applicable
              law, including by imposing reasonable overstay penalties and or
              fines by local law enforcement.
            </p>
          </section>
          <section id="travel-issues">
            <h5 className="mt-3 mb-3">
              3. Cancelations, Travel Issues, Refunds and Booking
              Modifications.
            </h5>
            <p>
              <b>3.1 Cancelations, Travel Issues, and Refunds.</b>{" "}
              In general, if as a Hunter you cancel a reservation, the amount
              refunded to you is determined by the {" "}
              cancelation policy that the Land Owner has
              assigned for that particular reservation. It is the Hunters
              responsibility to understand and read each cancelation for each
              reservation before booking, reservation may be beyond cancelation
              period at time of booking, If the Land Owner cancels reservation
              for any reason, a full refund will be due to the hunter.
            </p>
            <p>
              <b>3.2 Booking Modifications. </b> Hunters and Landowners are
              responsible for any booking modifications they agree to make via
              the iHunt platform or direct iHunt customer service and agree to
              pay any additional amounts, fees, or taxes associated with any
              Booking Modification.
            </p>
          </section>
          <section id="responsibility">
            <h5 className="mt-3 mb-3">
              4. Your Responsibilities and Assumption of Risk.
            </h5>
            <p>
              <b> 4.1 Your Responsibilities.</b>{" "}
              You are responsible and liable for your own acts and omissions and
              are also responsible for the acts and omissions of anyone you
              invite to join or provide access to any property or facility that
              is on the iHunt platform, For example, this means: (i) you are
              responsible for leaving the property you are hunting on (and
              related personal property) in the condition it was in when you
              arrived, and (ii) you must act with integrity, treat others with
              respect, and comply with applicable laws at all times. If you are
              booking for an additional hunter including one who is a minor or
              if you bring a minor along on a hunt, you must be legally
              authorized to act on behalf of the minor and you are solely
              responsible for the supervision of that minor.
            </p>
            <p>
              <b>4.2 Your Assumption of Risk.</b>{" "}
              You acknowledge that many activities such as hunting, hiking thru
              woods or lands, or any activity on the iHunt platform carry
              inherent risks, Including but not limited to, wild animals, poison
              plants, treacherous terrain, sudden and violent weather changes,
              snakes, mosquitoes, loaded fire arms and the use of knives and
              other sharp and dangerous objects and other outdoor dangers, such
              as tree stands, hunting blinds and other man made objects that may
              pose a threat to your wellbeing and agree that, to the maximum
              extent permitted by applicable law, you assume the entire risk
              arising out of your access to and use of the iHunt Platform,
              including any and all time of the duration of your hunting
              experience, or use of any iHunt Services, or any other
              interaction you have with other Members whether in person or
              online. This means it is your responsibility to investigate a land
              owners services to determine whether it is suitable for you. For
              example, a land owners property listing may carry risk of illness,
              bodily injury, disability, or death, and you freely and willfully
              assume those risks by choosing to participate in any Hunt or
              experience that you reserve. iHunt in no way will assume any
              liability for the safety of any hunter or participant involved in
              the iHunt platform, it is each members responsibility to evaluate
              and decide if they will accept the inherent risk involved by
              participating in the use of the services on the iHunt platform.
            </p>
          </section>
          <h2>Land Owner Terms</h2>
          <section id="listing">
            <h5 className="mt-3 mb-3">5. Renting Land oniHunt.</h5>
            <p>
              <b>5.1 Land Renter.</b>{" "}
              As a Land Renter, <b>iHunt</b> offers you the opportunity to
              share your Property with Other Hunting Enthusiasts - and earn
              money doing it. It’s easy to rent your land and you are in control
              of how you rent your land - set your price, availability, length
              of hunts and rules for each Listing.
            </p>
            <p>
              <b>5.2 Contracting with Hunters.</b>{" "}
              When you accept a Hunt request, or receive a Hunt confirmation
              through the iHunt Platform, you are entering into a contract
              directly with the Hunter, and are responsible for delivering your
              Hunting Experience under the terms and at the price specified in
              your Listing. You are also agreeing to pay applicable fees like{" "}
              iHunt service fee and applicable <b>taxes</b>{" "}
              should there be Any for each Hunt. <b>iHunt</b> Payments will
              deduct amounts you owe from your payout unless we and you agree to
              a different method. Any terms, policies or conditions that you
              include in any supplemental contract with Hunters must: (i) be
              consistent with our Terms, and the information provided in your
              Listing, and (ii) be prominently disclosed in your Listing
              description.
            </p>
            <p>
              <b> 5.3 Independence of Land Owners.</b> Your relationship with{" "}
              <b>iHunt</b> is that of an independent individual or entity and
              not an employee, agent, joint venturer, or partner of{" "}
              <b>iHunt</b> , except that <b>iHunt</b> Payments acts as a
              payment collection agent as described in the Payments Terms.
              iHunt does not direct or control your Land Rent, and you agree
              that you have complete discretion whether and when to provide Land
              Rental, and at what price and on what terms to offer them.
            </p>
          </section>{" "}
          <section id="manage">
            <h5 className="mt-3 mb-3">6. Managing Your Listing.</h5>
            <p>
              <b>6.1 Creating and Managing Your Listing. </b>
              The<b> iHunt </b> Platform provides tools that make it easy for
              you to set up and manage a Listing. Your Listing must include
              complete and accurate information about your Land Lease, your
              price, any other fees including damage fees, deposits, and any
              rules or requirements that apply to your Hunters or Listing. You
              are responsible for keeping your Listing information (including
              calendar availability) and content (like photos) up-to-date and
              accurate at all times.{" "}
              <b>
                <u>
                  We recommend that you obtain appropriate insurance for your
                  Services and suggest you carefully review policy terms and
                  conditions including coverage details and exclusions.
                </u>
              </b>{" "}
              You may only maintain one Listing per Hunt Accommodation, but may
              have multiple Listings for a single plot of land if it has
              multiple places to hunt.
            </p>
            <p>
              <b>6.2 Know Your Legal Obligations.</b> You are responsible for
              understanding and complying with any laws, rules, regulations, and
              contracts with the state of your land lease. For example: In some
              places, the hunt you want to offer may be prohibited. Check your
              local rules to learn what rules apply to the land lease you plan
              to offer. Information we provide regarding legal requirements is
              for informational purposes only and you must independently confirm
              your obligations. You are responsible for handling and using
              personal data of hunters and others in compliance with applicable
              privacy laws.
            </p>
            <p>
              <b>6.3 Your Responsibilities.</b> You are responsible and liable
              for your own acts and omissions and are also responsible for the
              acts and omissions of anyone you allow to participate in providing
              your land lease You are responsible for setting your price and
              establishing rules and requirements for your Listing. You must
              describe any and all fees and charges in your Listing description
              and may not collect any additional fees or charges outside the{" "}
              <b> iHunt </b> Platform Do not encourage Hunters to create
              third-party accounts, submit reviews, provide their contact
              information, or take other actions outside the
              <b> iHunt </b> Platform.
            </p>
            <p>
              <b>6.4 Renting as a Team or Organization.</b> If you work with a
              co-land owner or land owner as part of a team, business, or other
              organization, the entity and each individual who participates in
              providing land leases is responsible and liable as a Land owner
              under these Terms. If you accept terms or enter into contracts,
              you represent and warrant that you are authorized to enter into
              contracts for and bind your team, business or other organization,
              and that each entity you use is in good standing under the laws of
              the place where it is established. If you perform other functions,
              you represent and warrant that you are authorized to perform those
              functions. If you instruct <b>iHunt</b> to transfer a portion of
              your payout to a co-Land Owners or other Agents, or to send
              payments to someone else, you must be authorized to do so, and are
              responsible and liable for the payment amounts and accuracy of any
              payout information you provide.
            </p>
            <p>
              <b>6.5 Your Assumption of Risk.</b>
              You acknowledge that land lease carries inherent risks and agree
              that you assume the entire risk arising out of your access to and
              use of the <b>iHunt</b> Platform, or any interaction you have
              with other Hunters whether in person or online. You agree that you
              have had the opportunity to investigate the <b>iHunt</b> Platform
              and any laws, rules, regulations, or obligations that may be
              applicable to your land lease and that you are not relying upon
              any statement of law made by <b>iHunt</b>.
            </p>
          </section>
          <section id="cancelation">
            <h5 className="mt-3 mb-3">
              7. Cancelations, Travel Issues, and Booking Modifications.
            </h5>
            <p>
              <b>7.1 Cancelations and Travel Issues. </b>
              In general, if a Hunter cancels a reservation, the amount paid to
              you is determined by the cancelation policy that applies to that
              reservation. As a land owner, you should not cancel on a Hunter
              without a valid reason. If you cancel on a Hunter without a valid
              reason, we may impose a <b>cancelation fee</b>. The amount you
              are paid will be reduced by the amount we refund or otherwise
              provide to the Hunter, and by any other reasonable costs we incur
              as a result of the cancelation. If a Hunter receives a refund
              after you have already been paid, or the amount of the refund and
              other costs incurred by <b>iHunt</b> exceeds your payout,{" "}
              <b>iHunt</b> (via <b>iHunt</b> Payments) may recover that amount
              from you, including by offsetting the refund against your future
              payouts. You agree that If we reasonably are expected to provide a
              refund to a hunter under any terms, we may delay release of any
              payout for that reservation until a refund decision is made.
            </p>
            <p>
              <b>7.2 Booking Modifications.</b> Land Owners and Hunters are
              responsible for any Booking Modifications they agree to make via
              the <b>iHunt</b> Platform or direct iHunt customer service to
              make on their behalf, and agree to pay any additional amounts,
              fees or taxes associated with a Booking Modification.
            </p>
          </section>
          <section id="taxes">
            <h5 className="mt-3 mb-3">8. Taxes.</h5>
            <p>
              <b>8.1 Land Owner Taxes.</b> As a land owner, you are responsible
              for determining and fulfilling your obligations under applicable
              laws to report, collect, remit, or include in your price any
              applicable tax or other indirect taxes.
            </p>
            <p>
              <b>8.2 Collection and Remittance by iHunt.</b> As a whole, land
              leases are not subject to a sales tax on and transaction for
              hunting purposes, but, In jurisdictions where <b>iHunt</b>{" "}
              facilitates the collection and/or remittance of Taxes on behalf of
              land owners, you instruct and authorize. <b> iHunt </b>
              to collect Taxes on your behalf, and/or to remit such Taxes to the
              relevant Tax authority. Any Taxes that are collected and/or
              remitted by <b>iHunt</b> are identified to land owners on their
              transaction records, as applicable.{" "}
              <b>iHunt</b> may seek additional amounts from land owners
              (including by deducting such amounts from future payouts) in the
              event that the Taxes collected and/or remitted are insufficient to
              fully discharge that land owners’ tax obligations, and you agree
              that your sole remedy for Taxes collected by
              <b> iHunt</b> is a refund from the applicable Tax authority. You
              acknowledge and agree that we retain the right, with prior notice
              to affected land owners, to cease the collection and remittance of
              Taxes in any jurisdiction for any reason.
            </p>
            <p>
              <b> 8.3 Tax Information. </b> In certain jurisdictions, Tax
              regulations may require that we collect and/or report Tax
              information about you, or withhold Taxes from payouts to you, or
              both. If you fail to provide us with documentation that we
              determine to be sufficient to support any such obligation to
              withhold Taxes from payouts to you, we may withhold payouts up to
              the amount as required by law, until sufficient documentation is
              provided. You agree that iHunt may issue on your behalf invoices
              or similar documentation for any other Taxes for your land lease
              Services to facilitate accurate tax reporting by you, our hunters,
              and/or their organizations.
            </p>
          </section>
          <h2>General Terms</h2>
          <section id="reviews">
            <h5 className="mt-3 mb-3">9. Reviews.</h5>
            <p>
              After each Hunt, Hunters and Land Owners will have an opportunity
              to review each other. Your Review must be accurate and may not
              contain any discriminatory, offensive, defamatory, or other
              language that violates our Content Policy or{" "}
              Review Policy. Reviews are not verified by{" "}
              <b>iHunt</b> for accuracy and may be incorrect or misleading.
            </p>
          </section>
          <section id="content">
            <h5 className="mt-3 mb-3">10. Content.</h5>
            <p>
              Parts of the <b>iHunt</b> Platform enable you to provide
              feedback, text, photos, audio, video, information, and other
              content (collectively, “<b>Content”</b>). By providing Content, in
              whatever form and through whatever means, you grant <b>iHunt</b>{" "}
              a non-exclusive, worldwide, royalty-free, irrevocable, perpetual,
              sub-licensable and transferable license to copy, modify, prepare
              derivative works of, distribute, publish and otherwise exploit,
              that Content, without limitation. Where <b>iHunt</b>{" "}
              pays for the creation of Content or facilitates its creation,{" "}
              <b>iHunt</b> may own that Content, in which case supplemental
              terms or disclosures will say that. You are solely responsible for
              all Content that you provide and warrant that you either own it or
              are authorized to grant <b>iHunt</b> the rights described in
              these Terms. You are responsible and liable if any of your Content
              violates or infringes the intellectual property or privacy rights
              of any third party. Content must comply with our{" "}
              Content Policy and{" "}
              Nondiscrimination Policy, which prohibit, among
              other things, discriminatory, obscene, harassing, deceptive,
              violent, and illegal content.
            </p>
          </section>
          <section id="fees">
            <h5 className="mt-3 mb-3">11. Fees.</h5>
            <p>
              <b>iHunt</b> may charge fees (and applicable Taxes) to land
              owners and hunters for use of the <b>iHunt</b> Platform. A fee of
              7.5% of transaction will be deducted from Land Owner lease amount
              as well as a 7.5% Fee will be charged to Hunter final amount for a
              total of 15% fee for using the <b>iHunt</b> services. Except as
              otherwise provided on the{" "}
              <b>iHunt</b> Platform, service fees are non-refundable.{" "}
              <b>iHunt</b> reserves the right to change the service fees at any
              time, and will provide Members notice of any fee changes before
              they become effective. Fee changes will not affect bookings made
              prior to the effective date of the fee change. If you disagree
              with a fee change you may terminate this agreement at any time.
            </p>
          </section>
          <section id="platform-rules">
            <h5 className="mt-3 mb-3">12. iHunt Platform Rules.</h5>
            <p>
              <b>12.1 Rules.</b> You must follow these rules and must not help
              or induce others to break or circumvent these rules.
            </p>
            <ul>
              <li className="mt-3">
                Act with integrity and treat God, and others with respect.
                <ul>
                  <li>
                    Do not lie, misrepresent something or someone, or pretend to
                    be someone else.
                  </li>
                  <li>
                    Be polite and respectful when you communicate or interact
                    with others.
                  </li>
                  <li>
                    Follow our Nondiscrimination Policy and do
                    not discriminate against or harass others.
                  </li>
                </ul>
              </li>
              <li className="mt-3">
                Do not scrape, hack, reverse engineer, compromise or impair the
                iHunt Platform
                <ul>
                  <li>
                    Do not use bots, crawlers, scrapers, or other automated
                    means to access or collect data or other content from or
                    otherwise interact with the iHunt Platform.
                  </li>
                  <li>
                    Do not hack, avoid, remove, impair, or otherwise attempt to
                    circumvent any security or technological measure used to
                    protect the iHunt Platform or Content.
                  </li>
                  <li>
                    Do not decipher, decompile, disassemble, or reverse engineer
                    any of the software or hardware used to provide the iHunt
                    Platform.
                  </li>
                  <li>
                    Do not take any action that could damage or adversely affect
                    the performance or proper functioning of the iHunt
                    Platform.
                  </li>
                </ul>
              </li>
              <li className="mt-3">
                Only use the iHunt Platform as authorized by these Terms or
                another agreement with us
              </li>
              <ul>
                <li>
                  You may only use another Member’s personal information as
                  necessary to facilitate a transaction using the iHunt
                  Platform as authorized by these Terms.
                </li>
                <li>
                  Do not use the iHunt Platform, our messaging tools, or
                  Members’ personal information to send commercial messages
                  without the recipient’s express consent.
                </li>
                <li>
                  You may use Content made available through the iHunt Platform
                  solely as necessary to enable your use of the iHunt Platform
                  as a Hunter or Landowner.
                </li>
                <li>
                  Do not use Content unless you have permission from the content
                  owner or the use is authorized by us in these Terms or another
                  agreement you have with us.
                </li>
                <li>
                  Do not request, make, or accept a booking or any payment
                  outside of the iHunt Platform to avoid paying fees, taxes or
                  for any other reason.{" "}
                </li>
                <li>
                  Do not require or encourage Hunter to open an account, leave a
                  review, complete a survey, or otherwise interact, with a
                  third-party website, application or service before, during or
                  after a reservation, unless authorized by iHunt.
                </li>
                <li>
                  Do not engage in any practices that are intended to manipulate
                  our search algorithm.
                </li>
                <li>
                  Do not book Land Owner Services unless you are actually using
                  the Land Owners Services.
                </li>
                <li>
                  Do not use, copy, display, mirror or frame the iHunt
                  Platform, any Content, any iHunt branding, or any page layout
                  or design without our consent.
                </li>
              </ul>
              <li className="mt-3">Honor your legal obligations</li>
              <ul>
                <li>
                  Understand and follow the laws that apply to you, including
                  privacy, data protection, and export laws.
                </li>
                <li>
                  If you provide us with someone else’s personal information,
                  you: (i) must do so in compliance with applicable law, (ii)
                  must be authorized to do so, and (iii) authorize us to process
                  that information
                </li>
                <li>
                  Read and follow our <b>Terms,</b> <b>Policies </b>and {" "}
                  <b>Standards</b>.
                </li>
                <li>
                  Do not use the name, logo, branding, or trademarks of iHunt
                  or others without permission.
                </li>
                <li>
                  Do not use or register any domain name, social media handle,
                  trade name, trademark, branding, logo, or other source
                  identifier that may be confused with iHunt branding.
                </li>
                <li>
                  Do not offer Land Owner Services that violate the laws or
                  agreements that apply to you.
                </li>
              </ul>
            </ul>
            <p>
              <b>12.2 Reporting Violations. </b>If you believe that a Member,
              Listing or Content poses an imminent risk of harm to a person or
              property, you should immediately contact local authorities before
              contacting iHunt. In addition, if you believe that a Member,
              Listing or Content has violated our Standards, you should report
              your concerns to iHunt. If you reported an issue to local
              authorities, iHunt may request a copy of that report. Except as
              required by law, you agree that we are not obligated to take
              action in response to any report.
            </p>
            <p>
              <b>12.3 Copyright Notifications.</b> If you believe that Content
              on the iHunt Platform infringes copyrights, please notify us in
              accordance with our Copyright Policy.
            </p>
          </section>
          <section id="termination">
            {" "}
            <h5 className="mt-3 mb-3">
              13. Termination, Suspension and other Measures.
            </h5>
            <p>
              <b>13.1 Term.</b> The agreement between you and iHunt reflected
              by these Terms is effective when you access the iHunt Platform
              (for example to create an account) and remains in effect until
              either you or we terminate the agreement in accordance with these
              Terms.
            </p>
            <p>
              <b>13.2 Termination.</b> You may terminate this agreement at any
              time by sending us an email or by deleting your account. iHunt
              may terminate this agreement and your account for any reason by
              giving you 30 days’ notice via email or using any other contact
              information you have provided for your account. iHunt may also
              terminate this agreement immediately and without notice and stop
              providing access to the iHunt Platform if you breach these Terms,
              you violate our Policies, you violate applicable laws, or we
              reasonably believe termination is necessary to protect iHunt, its
              Members, or third parties. If your account has been inactive for
              more than two years, we may terminate your account without prior
              notice.
            </p>
            <p>
              <b> 13.3 Member Violations. </b>If (i) you breach these <b>Terms</b>,
              our <b>Policies</b>, or our <b> Standards</b>, (ii) you violate
              applicable laws, regulations, or third-party rights, or (iii)
              iHunt believes it is reasonably necessary to protect iHunt, its
              Members, or third parties; iHunt may, with or without prior
              notice:
            </p>
            <ul className="mt-3 mb-3">
              <li>
                suspend or limit your access to or use of the iHunt Platform
                and/or your account;
              </li>
              <li>suspend or remove Listings, Reviews, or other Content;</li>
              <li>cancel pending or confirmed bookings; or</li>
              <li>
                suspend or revoke any special status associated with your
                account.
              </li>
            </ul>
            <p>
              For minor violations or where otherwise appropriate as iHunt
              determines in its sole discretion, you will be given notice of any
              intended measure by iHunt and an opportunity to resolve the
              issue. You may appeal actions taken by us under this Section by
              contacting customer service. If a reservation is canceled under
              this Section, the amount paid to the Land Owner will be reduced by
              the amount we refund or otherwise provide to the Hunter, and by
              any other costs we incur as a result of the cancelation.
            </p>
            <p>
              <b>13.4 Legal Mandates.</b> iHunt may take any action it
              determines is reasonably necessary to comply with applicable law,
              or the order or request of a court, law enforcement, or other
              administrative agency or governmental body, including the measures
              described above in Section 13.3.
            </p>
            <p>
              <b>13.5 Effect of Termination.</b> If you are a Land Owner and
              terminate your iHunt account, any confirmed booking(s) will be
              automatically canceled and your Hunters will receive a full
              refund. If you terminate your account as a Hunter, any confirmed
              booking(s) will be automatically canceled and any refund will
              depend upon the terms of the reservation’s cancelation policy.
              When this agreement has been terminated, you are not entitled to a
              restoration of your account or any of your Content. If your access
              to or use of the iHunt Platform has been limited, or your iHunt
              account has been suspended, or this agreement has been terminated
              by us, you may not register a new account or access or use the
              iHunt Platform through an account of another Member.
            </p>
            <p>
              <b>13.6 Survival.</b> Parts of these Terms that by their nature
              survive termination, will survive termination of this agreement,
              including Sections 2 through 26.
            </p>
          </section>
          <section id="modification">
            <h5 className="mt-3 mb-3">14. Modification.</h5>
            <p>
              iHunt may modify these Terms at any time. When we make material
              changes to these Terms, we will post the revised Terms on the
              iHunt Platform and update the “Last Updated” date at the top of
              these Terms. We will also provide you with notice of any material
              changes by email at least 30 days before the date they become
              effective. If you disagree with the revised Terms, you may
              terminate this agreement immediately as provided in these Terms.
              If you do not terminate your agreement before the date the revised
              Terms become effective, your continued access to or use of the
              iHunt Platform will constitute acceptance of the revised Terms.
            </p>
          </section>
          <section id="ihunt-role">
            <h5 className="mt-3 mb-3">15. iHunt’s Role.</h5>
            <p>
              We offer a platform that enables Members to publish, offer, search
              for, and book Hunting Services. While we work hard to ensure our
              Members have great experiences using iHunt, we do not and cannot
              control the conduct of Hunters or Land Owners. You acknowledge
              that iHunt has the right, but does not have any obligation, to
              monitor the use of the iHunt Platform and verify information
              provided by our Members. For example, we may review, disable
              access to, remove, or edit Content to: (i) operate, secure and
              improve the iHunt Platform (including for fraud prevention, risk
              assessment, investigation and customer support purposes); (ii)
              ensure Members’ compliance with these Terms; (iii) comply with
              applicable law or the order or requirement of a court, law
              enforcement or other administrative agency or governmental body;
              (iv) address Content that we determine is harmful or
              objectionable; (v) take actions set out in these Terms; and (vi)
              maintain and enforce any quality or eligibility criteria,
              including by removing Listings that don’t meet quality and
              eligibility criteria. Members acknowledge and agree that iHunt
              administers its <b>Policies</b> and <b>Standards</b>{" "}
              including decisions about whether and how to apply them to a
              particular situation, at its sole discretion. Members agree to
              cooperate with and assist iHunt in good faith, and to provide
              iHunt with such information and take such actions as may be
              reasonably requested by iHunt with respect to any investigation
              undertaken by iHunt regarding the use or abuse of the iHunt
              Platform. iHunt is not acting as an agent for any Member except
              for where iHunt Payments acts as a collection agent as provided
              in the Payments Terms.
            </p>
          </section>
          <section id="accounts">
            <h5 className="mt-3 mb-3">16. Member Accounts.</h5>
            <p>
              You must register an account to access and use many features of
              the iHunt Platform. Registration is only permitted for legal
              entities, partnerships and natural persons who are 18 years or
              older, Unless a parent or legal Guardian with an account linked to
              minor has signed iHunt files accepting any and all responsibility
              for said hunter and assumes all risk involved as outlined in our
              disclaimers and You represent and warrant that you are not a
              person or entity barred from using the iHunt Platform under the
              laws of the United States, your place of residence, or any other
              applicable jurisdiction. You must provide accurate, current, and
              complete information during registration and keep your account
              information up-to-date. You may not register more than one account
              or transfer your account to someone else. You are responsible for
              maintaining the confidentiality and security of your account
              credentials and may not disclose your credentials to any third
              party. You are responsible and liable for activities conducted
              through your account and must immediately notify iHunt if you
              suspect that your credentials have been lost, stolen, or your
              account is otherwise compromised. If and as permitted by
              applicable law, we may, but have no obligation to (i) ask you to
              provide identification or other information, (ii) undertake checks
              designed to help verify your identity or background, (iii) screen
              you against third-party databases or other sources and request
              reports from service providers, and (iv) obtain reports from
              public records of criminal convictions or sex offender
              registrations or their local equivalents.
            </p>
          </section>
          <section id="warranties">
            <h5 className="mt-3 mb-3">17. Disclaimer of Warranties.</h5>
            <p>
              We provide the iHunt Platform and all Content “as is” without
              warranty of any kind and we disclaim all warranties, whether
              express or implied. For example: (i) we do not endorse or warrant
              the existence, conduct, performance, safety, quality, legality or
              suitability of any Hunter, Land Owner, Hunt Services or third
              party listing; (ii) we do not warrant the performance or
              non-interruption of the iHunt Platform; and (iii) we do not
              warrant that verification, identity or background checks conducted
              on Listings or Members (if any) will identify past misconduct or
              prevent future misconduct. Any references to a Member or Listing
              being "verified" (or similar language) indicate only that the
              Member or Listing or iHunt has completed a relevant verification
              or identification process and nothing else. The disclaimers in
              these Terms apply to the maximum extent permitted by law. If you
              have statutory rights or warranties we cannot disclaim, the
              duration of any such statutorily required rights or warranties,
              will be limited to the maximum extent permitted by law.
            </p>
          </section>
          <section id="limitations">
            <h5 className="mt-3 mb-3">18. Limitations on Liability.</h5>
            <p>
              Neither iHunt (including its affiliates and personnel) nor any
              other party involved in creating, producing, or delivering the
              iHunt Platform or any Content will be liable for any incidental,
              special, exemplary or consequential damages, including lost
              profits, loss of data or loss of goodwill, service interruption,
              computer damage or system failure or the cost of substitute
              products or services, or for any damages for personal or bodily
              injury or emotional distress arising out of or in connection with
              (i) these Terms, (ii) the use of or inability to use the iHunt
              Platform or any Content, (iii) any communications, interactions or
              meetings you may have with someone you interact or meet with
              through, or as a result of, your use of the iHunt Platform, or
              (iv) publishing or booking of a Listing, including the provision
              or use of Land Owner Services, whether based on warranty,
              contract, tort (including negligence), product liability or any
              other legal theory, and whether or not iHunt has been informed of
              the possibility of such damage, even if a limited remedy set out
              in these Terms is found to have failed of its essential purpose.
            </p>
            <p>
              Except for our obligation to transmit payments to Land Owners
              under these Terms, or make payments under the iHunt Guarantee or,
              in no event will iHunt’s aggregate liability for any claim or
              dispute arising out of or in connection with these Terms, your
              interaction with any Members, or your use of or inability to use
              the iHunt Platform, any Content, or any Land Owner Service,
              exceed: (A) to Hunters, the amount you paid as a Hunter during the
              12-month period prior to the event giving rise to the liability,
              (B) to Land Owners, the amount paid to you as a Land Owner in the
              12-month period prior to the event giving rise to the liability,
              or (C) to anyone else, one hundred U.S. dollars (US $100).
            </p>
            <p>
              These limitations of liability and damages are fundamental
              elements of the agreement between you and iHunt. If applicable
              law does not allow the limitations of liability set out in these
              Terms, the above limitations may not apply to you.
            </p>
          </section>
          <section id="indemnification">
            <h5 className="nt-3 mb-3">19. Indemnification.</h5>
            <p>
              To the maximum extent permitted by applicable law, you agree to
              release, defend (at iHunt’s option), indemnify, and hold iHunt
              and their personnel harmless from and against any claims,
              liabilities, damages, losses, and expenses, including, without
              limitation, reasonable legal and accounting fees, arising out of
              or in any way connected with: (i) your breach of these Terms
              (including any supplemental or additional terms that apply to a
              product or feature) or our Policies or Standards, (ii) your
              improper use of the iHunt Platform, (iii) your interaction with
              any Member, participation in an Experience or other Land Owner
              Services, including without limitation any injuries, losses or
              damages (whether compensatory, direct, incidental, consequential
              or otherwise) of any kind arising in connection with or as a
              result of such interaction, experience, participation or use, (iv)
              your failure, or our failure at your direction, to accurately
              report, collect or remit Taxes, or (v) your breach of any laws,
              regulations or third party rights such as intellectual property or
              privacy rights.
            </p>
          </section>
          <section id="contract">
            <h5 className="mt-3 mb-3">20. Contracting Entities.</h5>
            <p>
              Based on your country of residence or establishment and what you
              are doing on the iHunt Platform, Schedule 1 below sets out the
              iHunt entity with whom you are contracting. If we identify
              through the iHunt Platform, an iHunt entity other than the one
              set out on Schedule 1 as being responsible for a product, feature
              or transaction, the iHunt entity so identified is your
              contracting entity with respect to that product, feature or
              transaction. If you change your country of residence or
              establishment, the iHunt company you contract with (as set out on
              Schedule 1) and the applicable version of the Terms of Service
              will be determined by your new country of residence or
              establishment, from the date on which your country of residence or
              establishment changes.
            </p>
          </section>
          <section id="governing-law">
            <h5 className="mt-3 mb-3">
              21. United States Governing Law and Venue.
            </h5>
            <p>
              If you reside or have your place of establishment in the United
              States, these Terms will be interpreted in accordance with the
              laws of the State of New York and the United States of America,
              without regard to conflict-of-law provisions. Judicial proceedings
              (other than small claims actions) that are excluded from the
              arbitration agreement in Section 23 must be brought in state or
              federal court in New York, unless we both agree to some other
              location. You and we both consent to venue and personal
              jurisdiction in New York.
            </p>
          </section>
          <section id="dispute-resolution">
            <h5 className="mt-3 mb-3">
              22. United States Dispute Resolution and Arbitration Agreement.
            </h5>
            <p>
              <b>22.1 Application. </b>This Arbitration Agreement only applies
              to you if your country of residence or establishment is the United
              States. If your country of residence or establishment is not the
              United States, and you nevertheless attempt to bring any legal
              claim against iHunt in the United States, this Arbitration
              Agreement will apply for determination of the threshold issue of
              whether this Section 23 applies to you, and all other threshold
              determinations, including residency, arbitrability, venue, and
              applicable law.
            </p>
            <p>
              <b>22.2 Overview of Dispute Resolution Process.</b> iHunt is
              committed to participating in a consumer-friendly dispute
              resolution process. To that end, these Terms provide for a
              two-part process for individuals to whom this Section 22 applies:
              (1) an informal negotiation directly with iHunt’s customer
              service team (described in paragraph 22.3, below), and if
              necessary (2) a binding arbitration administered by the American
              Arbitration Association (“
              <b>AAA</b>”). You and iHunt each retain the right to seek relief
              in small claims court as an alternative to arbitration.
            </p>
            <p>
              <b>
                {" "}
                22.3 Mandatory Pre-Arbitration Dispute Resolution and
                Notification.
              </b>{" "}
              At least 30 days prior to initiating an arbitration, you and
              iHunt each agree to notify the other party of the dispute in
              writing and attempt in good faith to negotiate an informal
              resolution. You must send your notice of dispute to iHunt by
              mailing it to iHunt for service:
              <b> iHunt LLC 1110 Clyde Marengo Rd. Clyde, NY. 14433.</b> iHunt
              will send its notice of dispute to the email address associated
              with your iHunt account. A notice of dispute must include: the
              party’s name and preferred contact information, a brief
              description of the dispute, and the relief sought. If the parties
              are unable to resolve the dispute within the 30-day period, only
              then may either party commence arbitration by filing a written
              Demand for Arbitration (available at <a href="https://www.adr.org/" target="_blank">www.adr.org</a>)
              with the AAA and providing a copy to the other party as specified
              in the AAA Rules (available at  <a href="https://www.adr.org/" target="_blank">www.adr.org</a>).
            </p>
            <p>
              <b>22.4 Agreement to Arbitrate. </b> You and iHunt mutually agree
              that any dispute, claim or controversy arising out of or relating
              to these Terms or the applicability, breach, termination,
              validity, enforcement or interpretation thereof, or any use of the
              iHunt Platform, Land Owner Services, or any Content
              (collectively, “Disputes”) will be settled by binding individual
              arbitration (the “Arbitration Agreement”). If there is a dispute
              about whether this Arbitration Agreement can be enforced or
              applies to our Dispute, you and iHunt agree that the arbitrator
              will decide that issue.
            </p>
            <p>
              <b>22.5 Exceptions to Arbitration Agreement. </b> You and iHunt
              each agree that the following causes of action and/or claims for
              relief are exceptions to the Arbitration Agreement and will be
              brought in a judicial proceeding in a court of competent
              jurisdiction (as defined by Section 21): (i) any claim or cause of
              action alleging actual or threatened infringement,
              misappropriation or violation of a party’s copyrights, trademarks,
              trade secrets, patents, or other intellectual property rights;
              (ii) any claim or cause of action seeking emergency injunctive
              relief based on exigent circumstances (e.g., imminent danger or
              commission of a crime, hacking, cyber-attack); or (iii) a request
              for the remedy of public injunctive relief. You and iHunt agree
              that the remedy of public injunctive relief will proceed after the
              arbitration of all arbitrable claims, remedies, or causes of
              action, and will be stayed pending the outcome of the arbitration
              pursuant to section 3 of the Federal Arbitration Act.
            </p>
            <p>
              <b>22.6 Arbitration Rules and Governing Law. </b> This Arbitration
              Agreement evidences a transaction in interstate commerce and the
              Federal Arbitration Act governs all substantive and procedural
              interpretation and enforcement of this provision. The arbitration
              will be administered by AAA in accordance with the Consumer
              Arbitration Rules and/or other AAA arbitration rules determined to
              be applicable by the AAA (the “AAA Rules“) then in effect, except
              as modified here. The AAA Rules are available at{" "}
              <a href="https://www.adr.org/" target="_blank">www.adr.org</a>. In order to initiate arbitration, a
              completed written demand (available at <a href="https://www.adr.org/" target="_blank">www.adr.org</a>
              ) must be filed with the AAA and provided to the other party, as
              specified in the AAA rules.
            </p>
            <p>
              <b>
                22.7 Modification to AAA Rules - Arbitration Hearing/Location.{" "}
              </b>{" "}
              In order to make the arbitration most convenient to you, iHunt
              agrees that any required arbitration hearing may be conducted, at
              your option: (a) in the U.S. county where you reside; (b) in Wayne
              County; (c) via phone or video conference; or (d) if all parties
              agree, by solely the submission of documents to the arbitrator.
            </p>
            <p>
              <b>
                22.8 Modification of AAA Rules - Attorney’s Fees and Costs.{" "}
              </b>{" "}
              Your arbitration fees and your share of arbitrator compensation
              shall be governed by the AAA Rules and, where appropriate, limited
              by the AAA Consumer Rules. If such costs are determined by the
              arbitrator to be excessive, iHunt will pay all arbitration fees
              and expenses. Either party may make a request that the arbitrator
              award attorneys’ fees and costs upon proving that the other party
              has asserted a claim, cross-claim or defense that is groundless in
              fact or law, brought in bad faith or for the purpose of
              harassment, or is otherwise frivolous, as allowed by applicable
              law and the AAA Rules.
            </p>
            <p>
              <b>22.9 Arbitrator’s Decision. </b> The arbitrator’s decision will
              include the essential findings and conclusions upon which the
              arbitrator based the award. Judgment on the arbitration award may
              be entered in any court with proper jurisdiction. The arbitrator
              may award any relief allowed by law or the AAA Rules, but
              declaratory or injunctive relief may be awarded only on an
              individual basis and only to the extent necessary to provide
              relief warranted by the claimant’s individual claim.
            </p>
            <p>
              <b>22.10 Jury Trial Waiver.</b> You and iHunt acknowledge and
              agree that we are each waiving the right to a trial by jury as to
              all arbitrable Disputes.
            </p>
            <p>
              <b>22.11 No Class Actions or Representative Proceedings.</b>{" "}
              You and iHunt acknowledge and agree that, to the fullest extent
              permitted by law, we are each waiving the right to participate as
              a plaintiff or class member in any purported class action lawsuit,
              class-wide arbitration, private attorney general action, or any
              other representative or consolidated proceeding. Unless we agree
              in writing, the arbitrator may not consolidate more than one
              party’s claims and may not otherwise preside over any form of any
              class or representative proceeding. If there is a final judicial
              determination that applicable law precludes enforcement of the
              waiver contained in this paragraph as to any claim, cause of
              action or requested remedy, then that claim, cause of action or
              requested remedy, and only that claim, cause of action or
              requested remedy, will be severed from this agreement to arbitrate
              and will be brought in a court of competent jurisdiction. In the
              event that a claim, cause of action or requested remedy is severed
              pursuant to this paragraph, then you and we agree that the claims,
              causes of action or requested remedies that are not subject to
              arbitration will be stayed until all arbitrable claims, causes of
              action and requested remedies are resolved by the arbitrator.
            </p>
            <p>
              <b>22.12 Severability.</b>{" "}
              Except as provided in Section 22.11, in the event that any portion
              of this Arbitration Agreement is deemed illegal or unenforceable,
              such provision will be severed and the remainder of the
              Arbitration Agreement will be given full force and effect.
            </p>
            <p>
              <b>22.13 Changes to Agreement to Arbitrate.</b> If iHunt changes
              this Section 22 after the date you last accepted these Terms (or
              accepted any subsequent changes to these Terms), you may reject
              that change by sending us written notice (including by email)
              within 30 days of the date the change is effective. Rejecting a
              new change, however, does not revoke or alter your prior consent
              to any earlier agreements to arbitrate any Dispute between you and
              iHunt (or your prior consent to any subsequent changes thereto),
              which will remain in effect and enforceable as to any Dispute
              between you and iHunt.
            </p>
            <p>
              <b>22.14 Survival.</b> Except as provided in Section 22.12 and
              subject to Section 13.6, this Section 22 will survive any
              termination of these Terms and will continue to apply even if you
              stop using the iHunt Platform or terminate your iHunt account.
            </p>
          </section>
          <section id="miscellaneous">
            <h5 className="mt-3 mb-3">23. Miscellaneous.</h5>
            <p>
              <b>23.1 Other Terms Incorporated by Reference.</b> Our Hunter
              Refund Policy, Content Policy,{" "}
              Nondiscrimination Policy, and other supplemental
              policies and terms linked to in these Terms apply to your use of
              the iHunt Platform, are incorporated by reference, and form part
              of your agreement with iHunt.
            </p>
            <p>
              <b>23.2 Interpreting these Terms.</b> Except as they may be
              supplemented by additional terms, conditions, policies,
              guidelines, standards, and in-product disclosures, these Terms
              (including those items incorporated by reference) constitute the
              entire agreement between iHunt and you pertaining to your access
              to or use of the iHunt Platform and supersede any and all prior
              oral or written understandings or agreements between iHunt and
              you. These Terms do not and are not intended to confer any rights
              or remedies upon anyone other than you and iHunt. If any
              provision of these Terms is held to be invalid or unenforceable,
              except as otherwise indicated in Section 22.11 above, such
              provision will be struck and will not affect the validity and
              enforceability of the remaining provisions. Where the word “will”
              is used in these Terms it connotes an obligation with the same
              meaning as “shall.”
            </p>
            <p>
              <b>23.3 No Waiver. </b>iHunt’s failure to enforce any right or
              provision in these Terms will not constitute a waiver of such
              right or provision unless acknowledged and agreed to by us in
              writing. Except as expressly set forth in these Terms, the
              exercise by either party of any of its remedies under these Terms
              will be without prejudice to its other remedies under these Terms
              or otherwise permitted under law.
            </p>
            <p>
              <b>23.4 Assignment.</b> You may not assign, transfer or delegate
              this agreement or your rights and obligations hereunder without
              iHunt's prior written consent. iHunt may without restriction
              assign, transfer or delegate this agreement and any rights and
              obligations hereunder, at its sole discretion, with 30 days’ prior
              notice.
            </p>
            <p>
              <b>23.5 Notice.</b> Unless specified otherwise, any notices or
              other communications to Members permitted or required under this
              agreement, will be provided electronically and given by iHunt via
              email, iHunt Platform notification, messaging service (including
              SMS), or any other contact method we enable and you provide
            </p>
            <p>
              <b>23.6 Third-Party Services.</b> The iHunt Platform may contain
              links to third-party websites, applications, services or resources
              (“<b>Third-Party Services</b>”) that are subject to different
              terms and privacy practices. iHunt is not responsible or liable
              for any aspect of such Third-Party Services and links to such
              Third-Party Services are not an endorsement.
            </p>
            <p>
              <b> 23.7 Google Terms.</b>{" "}
              Some translations on the iHunt Platform are powered by Google.
              Google disclaims all warranties related to the translations,
              express or implied, including any warranties of accuracy,
              reliability, and any implied warranties for merchantability,
              fitness for a particular purpose and non-infringement. Some areas
              of the iHunt Platform implement Google Maps/Earth mapping
              services, including Google Maps API(s). Your use of Google
              Maps/Earth is subject to the {" "}
              <a href="https://www.google.com/help/terms_maps/" target="_blank">
                Google Maps/Google Earth Additional Terms of Service.
              </a>
            </p>
            <p>
              <b>23.8 Apple Terms.</b> If you access or download our application
              from the Apple App Store, you agree to{" "}
              <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">
                Apple’s Licensed Application End User License Agreement
              </a>
              .
            </p>
            <p>
              <b>23.9 iHunt Platform Content. </b> Content made available
              through the iHunt Platform may be protected by copyright,
              trademark, and/or other laws of the United States and other
              countries. You acknowledge that all intellectual property rights
              for that Content are the exclusive property of iHunt and/or its
              licensors and agree that you will not remove, alter or obscure any
              copyright, trademark, service mark or other proprietary rights
              notices. You may not use, copy, adapt, modify, prepare derivative
              works of, distribute, license, sell, transfer, publicly display,
              publicly perform, transmit, broadcast or otherwise exploit any
              Content accessed through the iHunt Platform except to the extent
              you are the legal owner of that Content or as expressly permitted
              in these Terms. Subject to your compliance with these Terms,
              iHunt grants you a limited, non-exclusive, non-sublicensable,
              revocable, non-transferable license to (i) download and use the
              Application on your personal device(s); and (ii) access and view
              the Content made available on or through the iHunt Platform and
              accessible to you, solely for your personal and non-commercial
              use.
            </p>
            <p>
              <b>23.10 ihunt.com.</b> ihunt.com is a for-profit llc.
              non-exempt from income taxation under U.S. Internal Revenue Code
              Section 501(c)(3), operating as a private company.
            </p>
            <p>
              <b>23.11 Force Majeure. </b> iHunt shall not be liable for any
              delay or failure to perform resulting from causes outside its
              reasonable control, including, but not limited to, acts of God,
              natural disasters, war, terrorism, riots, embargoes, acts of civil
              or military authorities, fire, floods, accidents, pandemics,
              epidemics or disease, strikes or shortages of transportation
              facilities, fuel, energy, labor or materials.
            </p>
            <p>
              <b>23.12 Emails and SMS. </b> You will receive administrative
              communications from us using the email address or other contact
              information you provide for your account. Enrollment in
              additional email subscription programs will not affect the
              frequency of these administrative emails, though you should expect
              to receive additional emails specific to the program(s) to which
              you have subscribed. You may also receive promotional emails from
              us. No fee is charged for these promotional emails, but
              third-party data rates could apply. You can control whether you
              receive promotional emails using the notification preferences in
              your account settings. Please note that you will not be able to
              take advantage of certain promotions if you disable certain
              communication settings or do not have an iHunt account. In the
              U.S. if you consent to receive SMS (text messages) from us, you
              will be subject to our SMS Terms.
            </p>
            <p>
              <b>23.13 Contact Us.</b>{" "}
              If you have any questions about these Terms please{" "}
              <a
                className="link cursor-pointer"
                onClick={() => (window.location = "mailto:info@ihunt.com")}
              >
                {" "}
                info@ihunt.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
