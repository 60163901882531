import React, { useCallback, useState } from "react";
import listingImg from "assets/images/default.png";
import pinIcon from "assets/icons/pin.svg";
import AddVideoIcon from "assets/icons/video-add-icon.png";
import AddImageIcon from "assets/icons/image-add-icon.png";
import galleryIcon from "assets/icons/gallery-icon.png";
import videoIcon from "assets/icons/video-icon.png";
import { addFollowStands, deleteStand, toggleStandStatus, cancelStandTransferInvitaion } from "modules/dashboard/_redux/stand/standActions";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EditStandModal } from "./editModal/editModal";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { ConfirmationModal } from "components/UI/modals/confirmation-modal/confirmationModal";
import { useDispatch, useSelector } from "react-redux";
import toastHandler from "components/UI/toastHandler/toastHandler";
import "react-phone-input-2/lib/style.css";
import "./style.scss";
import { InitiateTransferModal } from "components/UI/modals/initiateTransfer-modal/InitiateTransferModal";
import AssignToGroupModal from "components/UI/modals/assign-to-group-modal/AssignToGroupModal";
import { Modal } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { createStandPost } from "modules/landingPage/_redux/landingPageActions";
import CreateBlogConfirmationModal from "components/UI/modals/create-blog-confirmation-modal/CreateBlogConfirmationModal";
import CreateVoucherModal from "components/UI/modals/create-voucher-modal/CreateVoucherModal";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


export default function StandItem({ stand, currentTab, value, setValue, groups }) {
  const history = useHistory();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [standId, setStandId] = useState("");
  const [toggle, setToggle] = useState(-1)
  const [actionType, setActionType] = useState("")
  const [isActive, setActive] = useState(stand?.is_following || false);
  const [showAssignGroupModal, setShowAssignGroupModal] = useState(false);
  const [showCreateBlogModal, setShowCreateBlogModal] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isError, setIsError] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const [standPostImages, setStandPostImages] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [bigSizeImages, setBigSizeImages] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [showBlogConfirmationModal, setShowBlogConfirmationModal] = useState(false);
  const [postId, setPostId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showCreateVoucherModal, setShowCreateVoucherModal] = useState(false);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [imageCountExceeded, setImageCountExceeded] = useState(false);
  const [videoCountExceeded, setVideoCountExceeded] = useState(false);

  const toastid = history.location.pathname;

  const uploadProgress = useSelector((state) => state.landingPage.uploadProgress);

  const reload = () => {
    setValue(value + 1)
  }

  const handleDeleteStand = (id) => {
    setStandId(id)
    setActionType("delete")
    setShowConfirmationModal(true)
  }

  const handleToggleStand = (id, toggle) => {
    setStandId(id)
    setToggle(toggle)
    setActionType("toggle")
    setShowConfirmationModal(true)
  }

  const handleToggle = async () => {
    if (localStorage.getItem("token")) {
      await dispatch(addFollowStands(stand?.id, toastid));
      setActive(() => !isActive);
      reload()
    } else {
      history.push("/auth/login")
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <i
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }} className="bi bi-three-dots-vertical three-dots" />
  ));

  const handleConfirmModalSubmit = (confirmed) => {
    if (confirmed) {
      if (actionType == "toggle") {
        const body = {
          active: toggle
        }
        dispatch(toggleStandStatus(standId, body, reload))
        setShowConfirmationModal(false);
      } if (actionType == "delete") {
        dispatch(deleteStand(standId, reload))
        setShowConfirmationModal(false);
      }
    } else {
      setShowConfirmationModal(false);
    }
  };

  const cancelTransfer = (id) => {
    dispatch(cancelStandTransferInvitaion(id, onDeleteSuccess, onDeleteError))
  }

  const onDeleteSuccess = () => {
    reload()
    toastHandler("Stand transfer request has been successfully canceled", "success");
  }

  const onDeleteError = () => {
    setLoading(false)
  }

  const onHideHandler = () => {
    setShowCreateBlogModal(false);
    setTitle("");
    setDescription("");
    setStandPostImages([]);
    setIsError(false);
    setIsContentError(false);
    setImageError(false);
    setImageCount(0);
    setBigSizeImages(0);
    setVideoCount(0);
    setUploadedMedia([]);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setIsError(false);
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setIsContentError(false);
  }

  const onImageChange = (e) => {
    const imageArray = []
    let count = imageCount;
    if (e?.target?.files) {
        Array.from(e?.target?.files)?.map(image => {
            if (count < 15) {
                imageArray.push({ type: 'image', data: image });
                count++;
            }
            if (image?.size > 35e6) {
                setBigSizeImages(() => bigSizeImages + 1)
            }
        })
        setUploadedMedia([...uploadedMedia, ...imageArray]);
        setImageCount(count);
        setIsContentError(false);
        setImageCountExceeded(count >= 15);
    }
  }

  const onVideoChange = (e) => {
    const videoArray = [];
    let count = videoCount;
    if (e?.target?.files) {
        Array.from(e?.target?.files)?.map(video => {
          if (count < 3) {
            videoArray.push({ type: 'video', data: video });
            count++;
          }
        });
        setUploadedMedia([...uploadedMedia, ...videoArray]);
        setVideoCount(count);
        setIsContentError(false);
        setVideoCountExceeded(count >= 3);
    }
  }

  const removeImageHandler = (event) => {
    const image = uploadedMedia?.find((data, index) => index == event.target.id);
    const result = uploadedMedia?.filter((data, index) => index != event.target.id);
    setUploadedMedia(result);
    setImageCount(imageCount - 1);
    if (image?.data?.size > 35e6) {
        setBigSizeImages(() => bigSizeImages - 1)
    }
    setImageError(false);
    setImageCountExceeded(false);
  }

  const removeVideoHandler = (event) => {
    const result = uploadedMedia?.filter((_, index) => index != event.target.id);
    setUploadedMedia(result);
    setVideoCount(videoCount - 1);
    setIsContentError(false);
    setVideoCountExceeded(false);
  }

  const SortableItem = ({ id, children }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: id });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {children}
      </div>
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor,{
        activationConstraint: {
          distance: 1
        }
    }),
  );

  const onDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      setUploadedMedia((items) => {
        const oldIndex = items.findIndex((item, index) => `media-${index}` === active.id);
        const newIndex = items.findIndex((item, index) => `media-${index}` === over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const renderMedia = useCallback((item, index) => (
    <SortableItem key={`media-${index}`} id={`media-${index}`}>
      {item.type === 'image' ? (
        <div key={`image-${index}`} className={item.data?.size > 35e6 ? `uploaded-image size-exceed-error` : `uploaded-image`}>
          <img className="preview-image" src={URL.createObjectURL(item.data)} alt="preview" />
          <span className="del-img" id={index} onClick={removeImageHandler}></span>
          <img className="type-icon" src={galleryIcon} alt="gallery-icon" />
        </div>
      ) : (
        <div key={`video-${index}-${item.data.name}`} className="uploaded-image">
          <video className="preview-image" key={`video-${index}-${item.data.name}`}>
            <source src={URL.createObjectURL(item.data)} type={item.data.type} key={`video-${index}-${item.data.name}`} />
          </video>
          <span className="del-img" id={index} onClick={removeVideoHandler}></span>
          <img className="type-icon" src={videoIcon} alt="video-icon" />
        </div>
      )}
    </SortableItem>
  ), [uploadedMedia]);

  const onSubmitHandler = (event) => {
    setLoading(true);
    event.preventDefault();
    if (title?.trim()?.length < 1) {
        setIsError(true);
        setLoading(false);
        return
    } if (description?.trim()?.length < 1 && uploadedMedia?.length < 1) {
        setIsContentError(true);
        if (title?.trim().length > 1) {
            setIsError(false);
        }
        setLoading(false);
    } else {
        const formData = new FormData();
        formData.append('title', title);
        if (description?.length > 1)
            formData.append('description', description);
        formData.append('stand_id', stand.id);

        if (uploadedMedia?.length > 0) {
          for (var i = 0; i < uploadedMedia.length; i++) {
            formData.append(`media[${i}]`, uploadedMedia[i].data);
          }
        }
        dispatch(createStandPost(formData, onSuccess, onError));
    }
  }

  const onSuccess = (response) => {
    setLoading(false);
    onHideHandler();
    setShowBlogConfirmationModal(true);
    setPostId(response?.id);
    setTitle(response?.title);
    setDescription(response?.description);
    setStandPostImages(standPostImages);
    setSuccessMessage("Your Blog Post Is Created!");
  }

  const onError = (message) => {
    setLoading(false);
    toastHandler(message, "error");
  }

  return (
    <>
      <div className="col-xxl-6 col-xl-12 py-2 fade-in">
        <div className="ms-listing-box">
          <div className="inner-content">
            <img
              src={stand?.image || listingImg}
              onError={(e) => (e.target.src = listingImg)}
              alt=""
            />
            <div className="overlay">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="text-white cursor-pointer"
                onClick={() => {
                  history.push(`/stand/${stand.id}`);
                }}
              >
                View Details
              </a>
            </div>
          </div>
          <div className="ms-listing-box-content">
            <div className="d-flex align-items-start justify-content-between">
              <h5 className="mb-0 pe-2">{stand?.title}</h5>
              {
                (currentTab == "listing" || currentTab == "inactive") && (
                  <Dropdown className="initiate-transfer" as={ButtonGroup}>
                    <Dropdown.Toggle as={CustomToggle} drop="down-centered"/>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setShowCreateVoucherModal(true)}>Create A Voucher</Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowCreateBlogModal(true)}>Create A Blog Post</Dropdown.Item>
                      {
                      stand?.ownership_transfer_request ?
                        <Dropdown.Item onClick={() => cancelTransfer(stand?.id)}>Cancel Transfer</Dropdown.Item> :
                        stand?.status == "approved" ? <Dropdown.Item onClick={() => setShow(true)}>Initiate Transfer</Dropdown.Item> : null
                      }
                      {
                        groups && groups?.length > 0 && <Dropdown.Item onClick={() => setShowAssignGroupModal(true)}>Assign To Group</Dropdown.Item>
                      }
                      {
                        currentTab == "listing" && <Dropdown.Item className="red-dropdown-item" onClick={() => handleToggleStand(stand.id, 0)}>Deactivate</Dropdown.Item>
                      } {
                        currentTab == "inactive" && <Dropdown.Item  onClick={() => handleToggleStand(stand.id, 1)}>Active</Dropdown.Item>
                      }
                      <Dropdown.Item className="red-dropdown-item" onClick={() => handleDeleteStand(stand.id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )
              }
              {
                (currentTab == "followed") && (
                  <div className="multi-action-btns">
                    <div className="img-btn add-to-favorite">
                      <span className="active btn btn-secondary btn-sm rounded-5 border-0" onClick={() => { handleToggle() }}>
                        <i className="bi bi-heart-fill" style={{
                          float: "right",
                          color: "#ffffff",
                        }}
                        ></i>
                      </span>
                    </div>
                    <span className="">
                      <i className="bi bi-star-fill pr-10" style={{
                        color: "#DF9C56",
                      }}></i>
                      {stand?.rating}
                    </span>
                  </div>
                )
              }

            </div>

            {currentTab ? (

              <> <div className="d-flex align-items-center justify-content-between h-85">
                {
                  stand?.distance ?
                    <ul className="address-distance">
                      <li>
                        <img className="me-2" src={pinIcon} alt="" />
                        <span>{stand?.address}</span>
                      </li>
                      <li>
                        <img className="me-2" src={pinIcon} alt="" />
                        <span>{stand?.distance}</span>
                      </li>
                    </ul> : <div className="ms-location">
                      <img src={pinIcon} alt="" />
                      <span>{stand?.address || "N/A"}</span>
                    </div>
                }
                {currentTab == "followed" && (
                  <div className="price-with-icon d-flex justify-content-end align-items-center ms-3">
                    {
                      stand?.available_specie?.image ? <img src={stand?.available_specie?.image} alt="" /> : null
                    }
                    {
                      stand?.price ? <span>${stand?.price}</span> : null
                    }
                  </div>
                )
                }
              </div>
                <hr className="my-1" />
                <div className="booking-deatils">
                  {
                    currentTab != "inactive" && currentTab != "followed" && currentTab != "listing" ? (
                      <>
                        <div className="booking-deatils-text">
                          <span className="fs-14 text-tertiary">Bookings</span>
                          <span className="count-bubble">
                            {stand?.reservation_counter}
                          </span>
                        </div>
                        <div className="deatils-link">
                          <Link
                            to={`/my-profile/stand-bookings/${stand?.id}/${stand?.title
                              }/${currentTab === "history" ? "activetab" : 0}`}
                            className=""
                          >
                            View Details &gt;{" "}
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        {
                          currentTab == "followed" ?
                            <>
                              {stand?.next_available_booking ?
                                <div className="next-available-booking">
                                  <p className="next-available-description">{stand?.booking_available_description}</p>
                                  <p className="next-available-date">{stand?.next_available_booking}</p>
                                </div> :
                                <div className="no-reservation-available">
                                  This Stand is fully booked
                                </div>}
                            </> :
                            <div
                              className={`text-${stand?.status === "rejected"
                                ? "secondary"
                                : stand?.status === "approved"
                                  ? "primary"
                                  : "warning"
                                } d-flex align-items-center fs-14`}
                            >
                              <span
                                className={`dot-${stand?.status === "rejected"
                                  ? "secondary"
                                  : stand?.status === "approved"
                                    ? "primary"
                                    : "warning"
                                  }`}
                              ></span>{" "}
                              {stand?.status === "rejected"
                                ? "Rejected"
                                : stand?.status === "approved"
                                  ? "Approved"
                                  : "Pending"}
                            </div>
                        }
                      </>
                    )}
                </div>


              </>
            ) : (
              <>
                <div className="stand-item-action mt-4">
                  <button
                    className="btn btn-primary font-roboto text-white me-2"
                    onClick={() => {
                      setEditModal(true);
                    }}
                  >
                    {stand.status === "draft" ? "CONTINUE" : "EDIT"}
                  </button>
                  {currentTab === "listing" ? (
                    <div
                      className={`text-${stand?.status === "rejected"
                        ? "secondary"
                        : stand?.status === "approved"
                          ? "primary"
                          : "warning"
                        } d-flex align-items-center fs-14`}
                    >
                      <span
                        className={`dot-${stand?.status === "rejected"
                          ? "secondary"
                          : stand?.status === "approved"
                            ? "primary"
                            : "warning"
                          }`}
                      ></span>{" "}
                      {stand?.status === "rejected"
                        ? "Rejected"
                        : stand?.status === "approved"
                          ? "Approved"
                          : "Pending"}
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <EditStandModal
          show={showEditModal}
          onHide={() => {
            setEditModal(false);
          }}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          onHide={handleConfirmModalSubmit}
          title="Confirmation"
          message={actionType == "delete" ? "Do you want to delete the stand?" :
            toggle == 1 ? "Do you want to Activate your stand? If you do so, this stand will be able to accept further reservations"
              : "Do you want to Deactivate the stand? If you do so, this stand will not accept further reservations"
          }
          yesString={"YES"}
          noString={"NO"}
          onlyYes={false}
          size={(toggle == 1 || toggle == 0) && "lg"}
        />
        
        <InitiateTransferModal
          show={show}
          setShow={setShow}
          loading={loading}
          setLoading={setLoading}
          stand={stand}
          reload={reload}
        />
        <AssignToGroupModal
          id={stand?.id}
          show={showAssignGroupModal}
          onHide={() => setShowAssignGroupModal(false)}
          groups={groups}
        />
        <Modal
          show={showCreateBlogModal}
          onHide={onHideHandler}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="create-blog-post-dialog"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div>
              <h4 className="title">Create New Post</h4>
              <form>
                <div className="form-floating custom-description">
                  <input
                    name="text"
                    value={title}
                    type="text"
                    onChange={(e) => handleTitleChange(e)}
                    className={isError ? "form-control form-control-error" : "form-control"}
                  ></input>
                  <label for="floatingInputGrid">Title</label>
                </div>
                {isError && <span className="title-error">Post title is required</span>}

                <div className="form-floating blog-desc custom-description">
                  <textarea name="text" className="form-control" value={description} onChange={(e) => handleDescriptionChange(e)}>
                  </textarea>
                  <label for="floatingInputGrid m-0">
                    Write something about this post (optional)
                  </label>
                </div>

                {(uploadedMedia?.length > 0) && (
                  <DndContext 
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd}
                  >
                    <SortableContext
                      items={uploadedMedia.map((_, index) => `media-${index}`)}
                      strategy={rectSortingStrategy}
                    >
                      <div className="uploaded-media-wrapper">
                          {uploadedMedia?.map((item, index) => renderMedia(item, index))}
                          {bigSizeImages > 0 && <div className="size-exceed-error-msg">Image must not exceed 35 MB!</div>}
                          {uploadProgress > 0 && uploadProgress <= 100 && (
                            <div className="progress-circle">
                              <svg viewBox="0 0 36 36" className="circular-chart">
                                <path
                                  className="circle-bg"
                                  d="M18 2.0845
                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <path
                                  className="circle"
                                  strokeDasharray={`${uploadProgress}, 100`}
                                  d="M18 2.0845
                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <text x="18" y="20.35" className="percentage">
                                  {uploadProgress}%
                                </text>
                              </svg>
                            </div>
                          )}
                      </div>
                    </SortableContext>
                  </DndContext>
                )}

                <div className="media-uploader-wrapper">
                  <div className={`image-uploader-wrapper ${imageCountExceeded ? 'exceeded' : ''}`}>
                      <img src={AddImageIcon} alt="upload media" />
                      <input type="file"
                          name='image'
                          value=""
                          id='image-create'
                          onChange={onImageChange}
                          disabled={imageCount >= 15 || loading}
                          accept="image/*" multiple />
                      <span className="max-limit"> Maximum 15 images are allowed Image must not exceed 35 MB</span>
                  </div>
                  <div className="seperator"></div>
                  <div className={`video-uploader-wrapper ${videoCountExceeded ? 'exceeded' : ''}`}>
                      <img src={AddVideoIcon} alt="upload media" />
                      <input type="file"
                          name='video'
                          value=""
                          id='video-create'
                          onChange={onVideoChange}
                          disabled={videoCount >= 3 || loading}
                          accept="video/*" multiple />
                      <span className="max-limit"> Maximum 3 videos are allowed</span>
                  </div>
                </div>
                {
                  imageError ? <div className="image-error-msg">Maximum 15 images are allowed</div> : null
                }
                {isContentError && <span className="error">You must provide post content or upload any media</span>}
                <div className="modal-action-btns mt-4 text-center">
                    <button className='modal-action-btn outlined' type='button' onClick={onHideHandler} disabled={loading}>CANCEL</button>
                    <button className='modal-action-btn filled' type='submit' onClick={onSubmitHandler} disabled={loading || bigSizeImages}>
                        {loading ? <Spinner /> : "CREATE"}
                    </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <CreateBlogConfirmationModal
          standId={stand.id}
          postId={postId}
          setPostId={setPostId}
          show={showBlogConfirmationModal}
          onHide={() => {
            setShowBlogConfirmationModal(false);
            onHideHandler();
          }}
          title={title}
          setTitle={setTitle}
          standPostImages={standPostImages}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
        <CreateVoucherModal
          show={showCreateVoucherModal}
          onHide={() => setShowCreateVoucherModal(false)}
          land={stand}
        />
      </div>
    </>
  );
}

