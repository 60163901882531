import React from "react";
import Modal from "react-bootstrap/Modal";
import junior from "assets/images/juniorHunter.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { handleJuniorHunterModal } from "modules/dashboard/_redux/dashboard/dashboardActions";


export default function JuniorHunterWelcomeModal() {

    const dispatch = useDispatch();

    const { showJuniorHunterModal } = useSelector(
        (state) => ({
            showJuniorHunterModal: state.dashboard.showJuniorHunterModal,
        }),
        shallowEqual
    );

    return (
        <Modal
            show={showJuniorHunterModal}
            onHide={() => dispatch(handleJuniorHunterModal(false))}
            className="junior-modal"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            size="lg"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="junior-img">
                        <img src={junior} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-9 flex-grow-1">
                        <h3 className="mb-0">Hello Junior Hunter</h3>
                        <p className="home-cover-txt mt-0">
                            iHunt now allows junior hunters to use this application. This will help them to get insights of different interesting hunting locations.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-md-3 mb-3">
                        <div className="list-junior-hunter">
                            <div className="img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="325" height="41" viewBox="0 0 325 41">
                                    <path id="Path_1286" data-name="Path 1286" d="M0,0H325s-14,6.5-21.846,18.621S305.277,41,286.169,41H43.077C25,41,33.395,30.738,25.031,18.621S0,0,0,0Z" fill="#ea9b45" />
                                </svg>
                            </div>
                            <div className="list">
                                <h6>Things Junior Hunters Can Do</h6>
                                <ul>
                                    <li>Signup in the application </li>
                                    <li>Explore different hunting locations </li>
                                    <li>Check reviews on stands </li>
                                    <li>View harvested trophies by Hunters </li>
                                    <li>Blogs by landowners </li>
                                    <li>Manage their favorite stands </li>
                                    <li>View different helpful tutorials</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row h-100 align-content-between">
                            <div className="col-12 mb-lg-0 mb-md-3 mb-3">
                                <div className="list-junior-hunter black">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="325" height="41" viewBox="0 0 325 41">
                                            <path id="Path_1286" data-name="Path 1286" d="M0,0H325s-14,6.5-21.846,18.621S305.277,41,286.169,41H43.077C25,41,33.395,30.738,25.031,18.621S0,0,0,0Z" fill="#ea9b45" />
                                        </svg>
                                    </div>
                                    <div className="list">
                                        <h6>Things Junior Hunters Cannot Do</h6>
                                        <ul>
                                            <li>They cannot create their own stands</li>
                                            <li>They cannot reserve any stand</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-lg-0 mb-md-3 mb-3">
                                <div className="list-junior-hunter green notList">
                                    <div className="img">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="325" height="41" viewBox="0 0 325 41">
                                            <path id="Path_1286" data-name="Path 1286" d="M0,0H325s-14,6.5-21.846,18.621S305.277,41,286.169,41H43.077C25,41,33.395,30.738,25.031,18.621S0,0,0,0Z" fill="#ea9b45" />
                                        </svg>
                                    </div>
                                    <div className="list">
                                        <h6>Good News</h6>
                                        <ul>
                                            <li>18+ hunters can invite junior hunters on their hunts.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
