import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pin from "../../../../assets/icons/pin.svg";
import { addFollowStands } from "modules/dashboard/_redux/stand/standActions";
import { useLandingPageUIContext } from "../../pages/LandingPageUIContext";
import defaultImage from "assets/images/default.png";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "./style.scss";


export default function LandBookingItem({ land, isMap, isFeatured }) {
  const [userLocation, setUserLocation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const toastid = history.location.pathname;

  const landingPageUIContext = useLandingPageUIContext();
  const landingPageUIProps = useMemo(() => {
    return {
      openStandDetailPage: landingPageUIContext.openStandDetailPage,
      queryParams: landingPageUIContext.queryParams,
    };
  }, [landingPageUIContext]);

  const [isActive, setActive] = useState(land?.is_following || false);

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleToggle = () => {
    if (localStorage.getItem("token")) {
      dispatch(addFollowStands(land?.id, toastid));
      setActive(() => !isActive);
    } else {
      history.push("/auth/login")
    }
  }

  const handleRatingClick = () => {
    if (localStorage.getItem("token")) {
      history.push(`/stand/${land?.id}?from=stand-to-review`)
    } else {
      history.push("/auth/login")
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(location => {
      setUserLocation(true);
    })
  }, [])

  useEffect(() => {
    setActive(land?.is_following || false);
  }, [land?.is_following]);

  return (
    <div style={
      { position: "relative" }
    }>
      <div className="carusal-item">
        <Slider
          {...settings}
          style={{
            margin: "auto",
          }}
        >
          {land?.pictures?.map((picture, index) => (
            <div key={index}>
              <img
                src={picture.picture || defaultImage}
                alt=""
                onError={(e) => (e.target.src = defaultImage)}
              />
            </div>
          ))}
        </Slider>
        <div className="img-btn add-to-favorite">
          <span className="btn btn-secondary btn-sm rounded-5 border-0" onClick={handleToggle}>
            {
              isActive ?
                <i className="bi bi-heart-fill" style={{
                  float: "right",
                  color: "#EA9B45",
                }}
                ></i> :
                <i className="bi bi-heart" style={{
                  float: "right",
                  color: "black"
                }}
                ></i>
            }
          </span>
        </div>
        <div
          className="img-btn"
        >
          <span className="btn btn-secondary btn-sm rounded-3 border-0" onClick={handleRatingClick}>
            <i className="bi bi-star-fill"></i>
            {land?.rating}
          </span>
        </div>
        <div
          className="overlay cursor-pointer"
          onClick={() => {
            localStorage.setItem("fromStandDetails", true);
            landingPageUIProps.openStandDetailPage(
              land?.id,
              isMap,
              landingPageUIProps.queryParams
            );
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="text-white cursor-pointer">View Details</a>
        </div>
      </div>
      <div className="course-details">
        <div
          className="details-top"
          onClick={() => {
            localStorage.setItem("fromStandDetails", true);
            landingPageUIProps.openStandDetailPage(
              land?.id,
              isMap,
              landingPageUIProps.queryParams
            );
          }}
        >
          <div>
            <h5 style={{
              width: "100%"
            }}>{land?.title}
            </h5>
            {
              userLocation ?
                <ul className="address-distance">
                  <li>
                    <img className="me-2" src={Pin} alt="" />
                    <span>{land?.address}</span>
                  </li>
                  <li>
                    <img className="me-2" src={Pin} alt="" />
                    <span>{land?.distance}</span>
                  </li>
                </ul> : <p>
                  <img className="me-2" src={Pin} alt="" />
                  {land?.address}
                </p>
            }
          </div>
          <div className="price-with-icon d-flex justify-content-end align-items-center">
            {
              land?.available_specie?.image ? <img src={land?.available_specie?.image} alt="" /> : null
            }
            {
              land?.price ? <span>${land?.price}</span> : null
            }
          </div>
        </div>
        <div
          className="detail-btm"
          onClick={() => {
            localStorage.setItem("fromStandDetails", true);
            landingPageUIProps.openStandDetailPage(
              land?.id,
              isMap,
              landingPageUIProps.queryParams
            );
          }}
        >
          {land?.next_available_booking != null &&
            <div className="next-available-booking">
              <p className="next-available-description">{land?.booking_available_description}</p>
              <p className="next-available-date">{land?.next_available_booking}</p>
            </div>}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {land?.next_available_booking ? land?.created_by !== user?.id ? (
            <button
              onClick={() => {
                if (land?.created_by === user?.id) {
                  history.push(`/my-profile/update-stand/${land.id}`);
                  return;
                }
              }}
              className="btn btn-secondary rounded-3 text-darkblack mt-1"
            >
              {land?.created_by === user?.id ? "UPDATE" : "BOOK NOW"}
            </button>
          ) : null : <div className="no-reservation-available">
            This Stand is fully booked
          </div>}
        </div>
      </div>
    </div >
  );
}
