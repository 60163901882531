import CustomSpinner from "components/layout/Loader/Spinner";
import toastHandler from "components/UI/toastHandler/toastHandler";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useHistory } from "react-router";
import CurrentWeather from "../currentweather";
import WeeklyWeather from "../weeklyweather";
import ElevationGraph from "../standDetail/elevation-graph/elevation-graph";
import "./style.scss";
import { CancelationPolicyModal } from "components/UI/modals/cancelationPolicyModal";


export default function Description({ land, weatherReport }) {
  const history = useHistory();
  const toastId = history.location.pathname;
  const [show, setShow] = useState(false);

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    }),
    shallowEqual
  );
  const onHide = () => {
    setShow(false)
  }

  const showModal = () => {
    setShow(true)
  }

  const handleRulesNavigation = (ruleLink) => {
    if (!ruleLink) {
      toastHandler("Link not available", "error", toastId);
      return;
    }
    window.open(ruleLink, "_blank");
  };

  return (
    <>
      {land ? (
        <div className="desc">
          <div className="spec">
            <div className="mt-4 pt-2 fs-22 fw-bold mb-4">Species</div>
            <div className="species-list">
              {land?.specie && land?.specie?.length > 0
                ? land?.specie?.map((specie, index) => (
                  <div key={index}>
                    <img src={specie?.image} alt="" />
                    <p>{specie?.specie_name}</p>
                  </div>
                ))
                : "No Specie Found"}
              <div></div>
            </div>
          </div>
          <div className="mt-5 mb-3 fs-22 fw-bold">
            Description & Info
          </div>
          <p className="text-tertiary">{land?.rules || "N/A"}</p>
          <a className="cancellation-policy" onClick={showModal}>
            View Cancelation Policy
          </a>
          <div className="stand-detail">
            <div className="mt-4 mb-3 fs-22 fw-bold">Stand Details</div>
            <div className="stand-list">
              <div className="border-btm">
                <p className="mb-2">Acreage</p>
                <p className="fw-bold">{land?.acres || "0.0"}ac</p>
              </div>
              <div className="border-btm">
                <p className="mb-2">Location Terrain</p>
                {land?.terrains &&
                land?.terrains.length > 0 ? (
                  land?.terrains?.map((item, index) => {
                    return (
                      <span className="fw-bold">{item?.title}{index < land.terrains.length - 1 && ', '}</span>
                    );
                  })
                ) : (
                  <p className="fw-bold">N/A</p>
                )}
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Hunting Site</p>
                {land?.site_types &&
                land?.site_types.length > 0 ? (
                  land?.site_types?.map((item, index) => {
                    return (
                      <span className="fw-bold">{item?.type}{index < land.site_types.length - 1 && ', '}</span>
                    );
                  })
                ) : (
                  <p className="fw-bold">N/A</p>
                )}
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Maximum Sportsman on Property</p>
                <p className="fw-bold">
                  {land?.maximum_hunters_on_property ?? "N/A"}
                </p>
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Phone Service</p>
                <p className="fw-bold">
                  {land?.cellular_coverage === "true"
                    ? "Available"
                    : "Not Available"}
                </p>
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Distance from parking</p>
                <p className="fw-bold mb-2">
                  {land?.stand_distance ? `${land?.stand_distance} ft` : "N/A"}
                </p>
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Max Elevation Change</p>
                <p className="fw-bold">
                  {Math.round(land?.stand_average_elevation * 100) / 100 !== 0 ? `${Math.round(land?.stand_average_elevation * 100) / 100} ft` : "N/A"}
                </p>
              </div>
              <div className="border-btm mt-2">
                <p className="mb-2">Required Sportsman Rating (Min)</p>
                <p className="fw-bold">{land?.minimum_rating}</p>
              </div>
              {land?.lease_document && <div className="mt-2">
                <p className="mb-2">Lease Document</p>
                <a className="lease-btn" href={land?.lease_document} target="_blank">Click Here</a>
              </div>}
            </div>
          </div>
          <div className="row">
            {land?.multi_day_discounts?.filter(
              (item) => item.number_of_days != 365 && item.is_enabled
            ).length > 0 && (
                <div className="col-12">
                  <div className="multidays-discount">
                    <div className="mt-5 mb-4 fs-22 fw-bold">Multi Day Discounts</div>
                    <div className="days-box">
                      {land?.multi_day_discounts
                        ?.filter(
                          (item) => item.number_of_days != 365 && item.is_enabled
                        )
                        .map((item) => {
                          return (
                            <ul>
                              <li>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26.93"
                                    height="18.734"
                                    viewBox="0 0 26.93 18.734"
                                  >
                                    <g
                                      id="icon"
                                      transform="translate(26.93) rotate(90)"
                                    >
                                      <path
                                        id="Path_1148"
                                        data-name="Path 1148"
                                        d="M3.027,26.93H15.764a2.945,2.945,0,0,0,2.113-.85,2.9,2.9,0,0,0,.857-2.1V5.383a.7.7,0,0,0-.228-.453L9.71.085a.623.623,0,0,0-.628,0L.228,4.93A.514.514,0,0,0,0,5.383v18.6a2.9,2.9,0,0,0,.857,2.1,2.986,2.986,0,0,0,2.171.85ZM1.142,23.983V5.666L9.367,1.218l8.225,4.449v18.26a1.635,1.635,0,0,1-.571,1.3,1.866,1.866,0,0,1-1.314.567H3.027a1.659,1.659,0,0,1-1.314-.567,1.6,1.6,0,0,1-.571-1.247ZM9.367,9.973A2.55,2.55,0,1,0,6.8,7.423,2.549,2.549,0,0,0,9.367,9.973Zm0-3.967A1.417,1.417,0,1,1,7.939,7.423,1.409,1.409,0,0,1,9.367,6.007ZM5.54,22.085a.545.545,0,0,1,0-.793l6.854-6.857a.555.555,0,0,1,.8,0,.545.545,0,0,1,0,.793L6.34,22.085a.555.555,0,0,1-.8,0Zm1.714-4.363a1.87,1.87,0,1,0-1.885-1.87A1.894,1.894,0,0,0,7.254,17.722Zm0-2.607a.737.737,0,1,1-.743.737A.753.753,0,0,1,7.254,15.115ZM11.48,18.8a1.87,1.87,0,1,0,1.885,1.87A1.894,1.894,0,0,0,11.48,18.8Zm0,2.607a.737.737,0,1,1,.743-.736A.753.753,0,0,1,11.48,21.4Z"
                                        fill="#ea9b45"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                <b>{item.discount_percentage < 10 ? `0${item.discount_percentage}%` : `${item.discount_percentage}%`}</b>
                                Discount
                              </li>
                              <li className="seprator">/</li>
                              <li>
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.905"
                                    height="18.737"
                                    viewBox="0 0 19.905 18.737"
                                  >
                                    <g
                                      id="noun-calendar-5231776"
                                      transform="translate(0 0)"
                                    >
                                      <path
                                        id="Path_1191"
                                        data-name="Path 1191"
                                        d="M202.705,158.74h14.882a2.524,2.524,0,0,0,2.511-2.53V144.571a2.524,2.524,0,0,0-2.511-2.53h-1.529v-1.383a.651.651,0,1,0-1.3,0v1.383h-9.219v-1.383a.651.651,0,1,0-1.3,0v1.383h-1.529a2.524,2.524,0,0,0-2.511,2.53v11.64a2.522,2.522,0,0,0,2.511,2.529ZM201.5,144.572a1.216,1.216,0,0,1,1.209-1.218h14.882a1.216,1.216,0,0,1,1.209,1.218v11.64a1.216,1.216,0,0,1-1.209,1.218H202.705a1.216,1.216,0,0,1-1.209-1.218Z"
                                        transform="translate(-200.194 -140.003)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1192"
                                        data-name="Path 1192"
                                        d="M266.71,353.53a.813.813,0,1,0,.813.813A.818.818,0,0,0,266.71,353.53Z"
                                        transform="translate(-261.531 -339.241)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1193"
                                        data-name="Path 1193"
                                        d="M315.141,353.53a.813.813,0,1,0,.813.813A.815.815,0,0,0,315.141,353.53Z"
                                        transform="translate(-306.75 -339.241)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1194"
                                        data-name="Path 1194"
                                        d="M363.521,353.53a.813.813,0,1,0,.813.813A.815.815,0,0,0,363.521,353.53Z"
                                        transform="translate(-351.918 -339.241)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1195"
                                        data-name="Path 1195"
                                        d="M411.911,353.53a.813.813,0,1,0,.813.813A.815.815,0,0,0,411.911,353.53Z"
                                        transform="translate(-397.095 -339.241)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1196"
                                        data-name="Path 1196"
                                        d="M266.71,313.32a.813.813,0,1,0,.813.813A.818.818,0,0,0,266.71,313.32Z"
                                        transform="translate(-261.531 -301.722)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1197"
                                        data-name="Path 1197"
                                        d="M315.141,313.32a.813.813,0,1,0,.813.813A.815.815,0,0,0,315.141,313.32Z"
                                        transform="translate(-306.75 -301.722)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1198"
                                        data-name="Path 1198"
                                        d="M363.521,313.32a.813.813,0,1,0,.813.813A.815.815,0,0,0,363.521,313.32Z"
                                        transform="translate(-351.918 -301.722)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1199"
                                        data-name="Path 1199"
                                        d="M411.911,313.32a.813.813,0,1,0,.813.813A.815.815,0,0,0,411.911,313.32Z"
                                        transform="translate(-397.095 -301.722)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1200"
                                        data-name="Path 1200"
                                        d="M315.141,273.05a.813.813,0,1,0,.813.813A.815.815,0,0,0,315.141,273.05Z"
                                        transform="translate(-306.75 -264.147)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1201"
                                        data-name="Path 1201"
                                        d="M363.521,273.05a.813.813,0,1,0,.813.813A.815.815,0,0,0,363.521,273.05Z"
                                        transform="translate(-351.918 -264.147)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1202"
                                        data-name="Path 1202"
                                        d="M411.911,273.05a.813.813,0,1,0,.813.813A.815.815,0,0,0,411.911,273.05Z"
                                        transform="translate(-397.095 -264.147)"
                                        fill="#ea9b45"
                                      />
                                      <path
                                        id="Path_1203"
                                        data-name="Path 1203"
                                        d="M255.9,232.2h11.33a.656.656,0,0,0,0-1.312H255.9a.656.656,0,0,0,0,1.312Z"
                                        transform="translate(-251.565 -224.807)"
                                        fill="#ea9b45"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                <b>{item?.number_of_days < 10 ? `0${item?.number_of_days}` : `${item?.number_of_days}`}</b>Days
                              </li>
                            </ul>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            {
              land?.groups?.length > 0 && user && user?.id == land?.user?.id ?
                <div className="col-12 groups-sec">
                  <div className="mt-5 mb-4 fs-22 fw-bold">Groups</div>
                  <div className="new-tags-v2 stand-detail">
                    <div className="stand-list inner-tags d-flex justify-content-start w-auto tags-container">
                      {
                        land?.groups?.map(item => {
                          return (
                            <div className="tags-item">
                              <span>{item?.name}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div> : null
            }
            {land?.geo_location_p_s != null && (
              <div className="col-xxl-5 col-xl-6">
                <div className="elevation-graph">
                  <h4 className="mt-5 mb-3 fs-22 fw-bold">Elevation Profile</h4>
                  <ElevationGraph
                    elevations={JSON.parse(land?.geo_location_p_s)}
                  />
                </div>
              </div>
            )}
          </div>
          
          <div className="weather">
            <div className="mt-5 mb-3 fs-22 fw-bold">Weather</div>
            <div className="tab weather-tab">
              <Tabs
                transition
                id="controlled-tab-example"
                className="mb-4 pb-2"
                defaultActiveKey="Current"
              >
                <Tab eventKey="Current" title="Current">
                  <CurrentWeather weatherReport={weatherReport} />
                </Tab>
                <Tab eventKey="Weekly" title="Weekly">
                  <WeeklyWeather weatherReport={weatherReport} />
                </Tab>
              </Tabs>
            </div>
          </div>
                  
          {land && (land?.state?.hunting_license || land?.state?.state_hunting_rules) && (
            <div className="rules">
              <div className="mt-5 mb-3 fs-22 fw-bold">Links</div>
              {land?.state?.hunting_license && (<a
                className="link cursor-pointer"
                onClick={() =>
                  handleRulesNavigation(land?.state?.hunting_license)
                }
              >
                Buy hunting license
              </a>)}{" "}
              {land?.state?.hunting_license && land?.state?.state_hunting_rules && (
                <>
                  <br />
                  <a
                    className="link cursor-pointer"
                    onClick={() =>
                      handleRulesNavigation(land?.state?.state_hunting_rules)
                    }
                  >
                    State rules & regulation for hunting
                  </a>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
      <CancelationPolicyModal show={show}
        onHide={onHide}
        cancelationPolicy={land?.cancellation_policy} />
    </>
  );
}
